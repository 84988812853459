import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import axios from "axios";
import 'lib-flexible/flexible.js'
import './plugins/rem.js'

Vue.config.productionTip = false;
new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");


