import {
	get,
	post
} from '../config/http.js' //或者在main.js 统一引入这句话

// 'application/x-www-form-urlencoded;charset=utf-8'

export const getListForHome = (params) => {
	return get("/api/custom/homesetting/getListForHome", params);
}
// 消费明细
export const memberOfTheSales = (params, type) => {
	return post("/api/app/wexin/memberOfTheSales/", params, type);
}
// 传code
export const membershipCard = (params) => {
	return post("/api/app/wexin/membershipCard/", params);
}
// 根据手机号查询积分和会员信息
export const queryTheMember = (params) => {
	return post("/api/app/wexin/queryTheMember/", params);
}
// 微信激活激活接口
export const member = (params) => {
	return post("/api/app/wexin/member/", params);
}
// 获取微信用户头像
export const personalData = (params) => {
	return post("/api/app/wexin/personalData/", params);
}
// 获取code
export const wxLoginInit = (params) => {
	return post("/api/admin/cardTemplate/wxLoginInit/", params);
}
// export const storeList = (params, type) => {
// 	return get("/basjzt/app/wexin/storeList", params, type);
// }
