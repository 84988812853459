import axios from './axios.intercept.js';
// 封装axios的get、post等方法


/**
 * 封装get方法
 * @param {Object} url
 * @param {Object} params
 * @returns {Promise}
 */
export function get(url, params) {
	return new Promise((resolve, reject) => {
		axios.get(url, {
			params: params
		}).then(response => {
			resolve(response.data)
		}, err => {
			reject(err)
		});
	})
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @param {Object} contentType  请求头参数，传布尔类型:true or false
 * @returns {Promise}
 */
export function post(url, params,contentType) {
	return new Promise((resolve, reject) => {
		axios.post(url,params, {
			headers: {
				'content-type': contentType?contentType:'application/json;charset=UTF-8'
			}
		}).then(response => {
			resolve(response.data)
			// setTimeout(()=>{
			// }, 1000);
		}, err => {
			reject(err)
		});
	})
}


/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.patch(url, data).then(
			response => {
				resolve(response.data);
			},
			err => {
				reject(err);
			}
		);
	});
}
