import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
	  code:'',
	  memberPhone:'',
	  headimgurl:''
  },
  mutations: {
	  //登录状态
	  setCode (state, code) {
	      state.code = code
	  },
	  //手机号
	  setMemberPhone (state, memberPhone) {
	      state.memberPhone = memberPhone
	  },
	  //手机号
	  setHeadImg (state, headimgurl) {
	      state.headimgurl = headimgurl
	  },
  },
  actions: {},
  modules: {},
});
