import axios from 'axios'
import store from '../src/store/index.js'

//  创建axiox 实例
axios.create({
    // baseURL: '/api',
    timeout: 30000,
	 // headers: {
		// 'Content-Type': 'application/x-www-form-urlencoded'
	 //  }
})
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
// http request 拦截器
axios.interceptors.request.use(
    config => {
		// 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
		// 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
		let token=""
		var url = window.location.href;
	
        if(process.env.NODE_ENV=='development'){
         }
        // debugger
        if (localStorage.Authorization) {
            const token = localStorage.Authorization
            config.headers.Authorization = `${token}`
        }
		 // config.method === 'post'
		 //        ? config.data = qs.stringify({...config.data})
		 //        : config.params = {...config.params};
        return config
    },
    err => {
		console.log("error")
        return Promise.reject(err)
    }
)

// http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.code) {
            if(response.data.code=='418'){
                store.commit('errorMsg', response.data.description)
                return Promise.reject(response)
            }
            if(response.data.code=='401'){
                localStorage.removeItem('Authorization')
                localStorage.currentUrl = window.location.href
                if(store.state.userAgent=='weixin'){
                    // location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + config.wxInfo.appId + '&redirect_uri=' + config.loginUrl + '/login&response_type=code&scope=snsapi_base&state=STATE#wechat\_redirect'   
                    var formData = new FormData();
                    formData.append("backUrl",window.location.origin);
                    axios.post('api/custom/wechat/wxLoginInit',formData).then(res=>{
                        console.log("wxLoginInit->"+res);
                        location.href = res.data.data;
                    })
                }else{
                    // location.href =window.location.origin+'/login'
					// location.href =window.location.origin+'/authorizationLogin'
                }
                return Promise.reject(response)
            }
            if(response.data.code.errcode=='301001'){
                store.commit('errorMsg', response.data.code.errmessage)
                return Promise.reject(response)
            }
            if(response.data.code.errcode=='103001'){
                store.commit('errorMsg', response.data.code.errmessage)
                return Promise.reject(response)
            }
            if(response.data.code.errcode=='103007'){
                store.commit('errorMsg', response.data.code.errmessage)
                return Promise.reject(response)
            }
            if (response.data.code.errcode != 0) {
                // alert(response.data.code.errmessage)
            }
        }
		// let result;
		// switch(response.status){
		// 	case 500:
		// 	   console.log("服务器出错")
		// 	    break
		// 	case 200:
		// 	   console.log("服务器出错")
		// 		break
		// }
        return response
    },
    error => {
        if (error.response) {
            if (error.response.data.code == 500) {
                store.commit('errorMsg', error.response.data.description)
            }
            switch (error.response.status) {
                case 400:
                    store.commit('errorMsg', error.response.data.description)
                    store.commit('hideLoading')
                    break
                case 555:
                    store.commit('errorMsg', error.response.data.msg)
                    store.commit('yearMoney', true)
                    break
                case 401:
                    //跳转到登陆页面
                    localStorage.removeItem('Authorization')
                    localStorage.currentUrl = window.location.href
                    if(store.state.userAgent=='weixin'){
                        // location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + config.wxInfo.appId + '&redirect_uri=' + config.loginUrl + '/login&response_type=code&scope=snsapi_base&state=STATE#wechat\_redirect'   
                        // var formData = new FormData();
                        // formData.append("backUrl",window.location.origin);
                        // axios.post('api/custom/wechat/wxLoginInit',formData).then(res=>{
                        //     console.log("wxLoginInit->"+res);
                        //     location.href = res.data.data;
                        // })
                    }else{
                        location.href =window.location.origin+'/login'
						// location.href =window.location.origin+'/authorizationLogin'
                    }
                    break;
                case 402:
                    // 402 用户未注册跳转注册页面
                    //   router.replace({
                    //     path: '/login',
                    //   })
                    break
				case 404:
					console.log("404")
					break
                case 406:
                    store.commit('errorMsg', error.response.data.msg)
                    break
              
            }
        }
        // console : Error: Request failed with status code 402
        return Promise.reject(error.response)
    }
)

export default axios
