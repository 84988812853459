<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	import VConsole from 'vconsole/dist/vconsole.min.js'
	let vConsole = new VConsole()
	import axios from 'axios';
	const jweixin = require('jweixin-module');
	import {
		wxLoginInit
	} from '../api/home.js'
	export default {
		data() {
			return {
				code: '',
				storeNo: '00',
				href: location.href
			}
		},
		methods: {
			// 获取code
			GetRequest() {
				var url = decodeURI(location.search);
				var theRequest = new Object();
				if (url.indexOf("?") != -1) {
					var str = url.substr(1); //?后的字符串
					var strs = new Array();
					if (str.indexOf("&") != -1) {
						strs = str.split("&"); //分割成数组
					} else {
						strs.push(str);
					}
					for (var i = 0; i < strs.length; i++) {
						theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
					}
				}
				let request = {};
				request.code = theRequest['code'];
				request.storeNo = theRequest['storeNo'];
				if (!request.storeNo) {
					request.storeNo = '00'
				}
				console.log(request.code + "----------------------------------")
				console.log(request.storeNo + "----------------------------------")
				return request;
			},
			//获取code
			async wxLoginInit() {
				let res = await wxLoginInit({
					href: window.location.href
				});
				console.log(res)
				if (res.data) {
					console.log(res.data)
					// location.href=res.data
				}
				if (res.code) {
					this.$store.commit("setCode", res.code);
				}
			},

		},
		created() {
			axios.post(
				'https://member.boaiyiyao.com/api/admin/cardTemplate/getConfigParam', {
					pageUrl: location.href
				}).then(function(res) {
				console.log(res)
				if (res.data.code.errcode === '0') {
					console.log('456')
					let data = res.data.data
					jweixin.config({
						debug: false,
						appId: data.appId,
						timestamp: data.timeStamp,
						nonceStr: data.nonceStr,
						signature: data.paySign,
						jsApiList: [
							'onMenuShareTimeline',
							'onMenuShareAppMessage',
							'onMenuShareQQ',
							'onMenuShareWeibo',
							'onMenuShareQZone',
							'startRecord',
							'stopRecord',
							'onVoiceRecordEnd',
							'playVoice',
							'pauseVoice',
							'stopVoice',
							'onVoicePlayEnd',
							'uploadVoice',
							'downloadVoice',
							'chooseImage',
							'previewImage',
							'uploadImage',
							'downloadImage',
							'translateVoice',
							'getNetworkType',
							'openLocation',
							'getLocation',
							'hideOptionMenu',
							'showOptionMenu',
							'hideMenuItems',
							'showMenuItems',
							'hideAllNonBaseMenuItem',
							'showAllNonBaseMenuItem',
							'closeWindow',
							'scanQRCode',
							'chooseWXPay',
							'openProductSpecificView',
							'addCard',
							'chooseCard',
							'openCard'
						]
					})
				}
			})
			jweixin.ready(() => {
				console.log(window.location.href + "------------,-------------" + window.location.origin)
				// 判断有没有code
				let request = this.GetRequest();
				if (request.code) {
					console.log(window.location.origin + "======why:=====" + request.code)
					axios.post('https://member.boaiyiyao.com/api/admin/cardTemplate/getCardCode', {
						code: request.code
					}).then(function(res) {
						console.log(res)
						if (res.data.code.errcode === '0') {
							if (res.data.data.wxCardCode) {
								jweixin.openCard({
									cardList: [{
										cardId: res.data.data.wxCardId,
										code: res.data.data.wxCardCode
									}], // 需要添加的卡券列表
									success: function(res) {
										var cardList = res.cardList; // 添加的卡券列表信息
										console.log("cardList: " + cardList)
									},
									fail: function(res) {
										console.log(res)
									}
								});
							} else {
								axios.post(
									'https://member.boaiyiyao.com/api/admin/cardTemplate/getWxCardConfigParam', {
										storeNo: request.storeNo
									}).then(function(res) {
									console.log(res)
									if (res.data.code.errcode === '0') {
										jweixin.addCard({
											cardList: [{
												cardId: 'pTGApw8cpHpW4sTfzskHP_pBK0oE',
												cardExt: res.data.data
											}], // 需要添加的卡券列表
											success: function(res) {
												console.log("res: " + res)
												var cardList = res
													.cardList; // 添加的卡券列表信息
												console.log("cardList: " + cardList)
											},
											fail: function(res) {
												console.log(res)
											},
											cancel: function(res) {
												console.log(res)
											}
										});
									}
								})
							}
						} else {
							axios.post(
								'https://member.boaiyiyao.com/api/admin/cardTemplate/getWxCardConfigParam', {
									storeNo: request.storeNo
								}).then(function(res) {
								console.log(res)
								if (res.data.code.errcode === '0') {
									jweixin.addCard({
										cardList: [{
											cardId: 'pTGApw8cpHpW4sTfzskHP_pBK0oE',
											cardExt: res.data.data
										}], // 需要添加的卡券列表
										success: function(res) {
											console.log("res: " + res)
											var cardList = res.cardList; // 添加的卡券列表信息
											console.log("cardList: " + cardList)
										},
										fail: function(res) {
											console.log(res)
										},
										cancel: function(res) {
											console.log(res)
										}
									});
								}
							})
						}
					})
					this.$store.commit("setCode", this.code);
				} else {
					console.log('123')
					// return;
					window.location.href =
						"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3f0a7a735807e5d9&redirect_uri=" +
						window.location.href +
						"&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
				}
				// console.log(window.location.href+"------------,-------------"+ window.location.origin)
				// // 判断有没有code
				// this.code=this.GetRequest();
				// if (this.code) {
				// 	console.log( window.location.origin+"======why:=====" + this.code)
				// 	this.$store.commit("setCode", this.code);
				// } else {
				// 	// return;
				// 	window.location.href =
				// 		"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3f0a7a735807e5d9&redirect_uri="+window.location.href+"&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"	
				// }
			})
			jweixin.error((res) => {
				console.log(res)
			})
		}
	}
</script>
<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		color: #2c3e50;
	}

	body {
		margin: 0;
	}

	#nav {
		// padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}
</style>
